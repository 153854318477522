import { makeAutoObservable, toJS } from 'mobx'
import { now } from 'mobx-utils'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'

import type { Model } from '.'

const msPerDay = 1000 * 60 * 60 * 24

export type IdentityVerificationStatus =
  | 'unverified'
  | 'required'
  | 'processing'
  | 'verified'
  | 'failed'

export type ReviewStatus =
  | 'auto-approved'
  | 'needs-review'
  | 'rejected'
  | 'approved'
  | 'needs-identity'
  | 'limited'
  | 'frozen'

export type SubscriptionSettings = {
  [key: string]: unknown
  prepaidCreditThreshold?: number
}
export type SubscriptionStore = 'stripe' | 'ios' | 'android'
export type SubscriptionType = 'free' | 'standard' | 'premium' | 'scale'

const defaultPrepaidCreditThresholdCents = 100

interface BaseCodableSubscription {
  id: string
  annual: boolean | null
  autochargeAmount: number
  canceledAt: number | null
  cancellationReason: string | null
  creditCardFingerprint: string | null
  identityVerificationStatus: IdentityVerificationStatus | null
  meta: Record<string, unknown> | null
  orgId: string | null
  phoneNumbers: number | null
  prepaidCredits: number | null
  receipt: string | null
  reviewStatus: ReviewStatus | null
  settings: SubscriptionSettings | null
  store: SubscriptionStore | null
  stripeCustomerId: string | null
  totalCredits: number | null
  transactionId: string | null
  trial: boolean | null
  /**
   * Subscription type
   *
   * There are a lot of different types of subscriptions that exist in the database today,
   * but there are only a few that we handle in the app.
   * We should remove eventually broad `string` type in favour of a more specific type
   * https://linear.app/openphone/issue/WORK-3271/migrate-all-subscriptions-with-nullempty-type-to-a-valid-type#comment-7be49ad5
   */
  type: SubscriptionType | string | null
  usedCredits: number | null
  version: number | null
  identityFailedCount: number
  createdAt: string
  updatedAt: string | null
  coupon: string | null
  originatingUserId: string | null
  identityVerificationSecret: string | null
}
export interface CodableSubscription extends BaseCodableSubscription {
  currentPeriodExpiresAt: number | null
  currentPeriodStartedAt: number | null
  businessPreviewExpiresAt: number | null
  originalStartedAt: number | null
}
export interface EncodableSubscription extends BaseCodableSubscription {
  currentPeriodExpiresAt: string | number | null
  currentPeriodStartedAt: string | number | null
  businessPreviewExpiresAt: string | number | null
  originalStartedAt: string | number | null
}

class SubscriptionModel implements Model, CodableSubscription {
  id: string = objectId()
  annual: boolean | null = null
  autochargeAmount = 0
  cancellationReason: string | null = null
  creditCardFingerprint: string | null = null
  currentPeriodExpiresAt: number | null = null
  currentPeriodStartedAt: number | null = null
  businessPreviewExpiresAt: number | null = null
  identityVerificationStatus: IdentityVerificationStatus | null = null
  identityFailedCount = 0
  meta: Record<string, unknown> | null = null
  orgId: string | null = null
  originalStartedAt: number | null = null
  phoneNumbers: number | null = null
  prepaidCredits = 0
  receipt: string | null = null
  /**
   * Status that indicates current state of the organization
   *
   * Note: `needs-identity` is a legacy value. It was used for KYC during the onboarding process.
   */
  reviewStatus: ReviewStatus | null = null
  settings: SubscriptionSettings | null = null
  store: SubscriptionStore | null = null
  stripeCustomerId: string | null = null
  totalCredits: number | null = null
  transactionId: string | null = null
  trial: boolean | null = null
  type: SubscriptionType | null = null
  usedCredits: number | null = null
  version: number | null = null
  createdAt: string = new Date().toISOString()
  updatedAt: string | null = null
  coupon: string | null = null
  originatingUserId: string | null = null
  identityVerificationSecret: string | null = null
  canceledAt: number | null = null

  constructor() {
    makeAutoObservable(this, {})
  }

  /**
   * Whether or not the subscription is active.
   *
   * A subscription is active if it is either standard or premium and the
   * current period has not yet expired. Even trial subscriptions are active.
   */
  get isActive(): boolean {
    return this.type !== 'free' && this?.currentPeriodExpiresAt
      ? this.currentPeriodExpiresAt > Date.now()
      : false
  }

  /**
   * Whether or not the subscription was canceled by the user.
   */
  get isCanceled(): boolean {
    return Boolean(this.canceledAt)
  }

  /**
   * Whether or not the susbscription is premium.
   *
   * A subscription is premium if the user is on the premium plan.
   */
  get isPremium(): boolean {
    return this.type === 'premium'
  }

  /**
   * Whether or not the subscription is premium and active.
   *
   * A subscription is premium if the user is on the premium plan and active if the current period expires after this moment.
   */
  get isActivePremium(): boolean {
    return this.isPremium && this.isActive
  }

  /**
   * A private getter used internally by other Business Preview getters.
   *
   * It uses the mobx-utils `now()` function to get the current time as an
   * observable which is updated automatically after the given interval.
   *
   * The interval is calculated to be when the number of days remaining
   * changes to ensure the Business Preview UI is updated or
   * removed as the current date moves closer to or surpasses the expiry
   * date while only re-computing the value when necessary.
   *
   * @returns {(number|null)}
   *
   * - `null` if there is no expiry date
   *
   * - `-1` if the expiry date has already passed
   *
   * - the number of milliseconds until the expiry date
   */
  private get timeUntilBusinessPreviewExpiresMs(): number | null {
    if (!this.businessPreviewExpiresAt) {
      return null
    }

    if (Date.now() > this.businessPreviewExpiresAt) {
      return -1
    }

    const timeUntilExpiryMs = this.businessPreviewExpiresAt - Date.now()
    const timeUntilNextDayChangeMs = timeUntilExpiryMs % msPerDay
    const observedTimeUntilExpiryMs =
      this.businessPreviewExpiresAt - now(timeUntilNextDayChangeMs)

    return observedTimeUntilExpiryMs
  }

  get businessPreviewDaysRemaining(): number {
    if (
      this.timeUntilBusinessPreviewExpiresMs === null ||
      this.isBusinessPreviewExpired
    ) {
      return 0
    }

    const daysRemaining = Math.ceil(this.timeUntilBusinessPreviewExpiresMs / msPerDay)

    return Math.max(daysRemaining, 0)
  }

  get isBusinessPreviewActive() {
    return !this.isPremium && this.businessPreviewDaysRemaining > 0
  }

  get isBusinessPreviewExpired() {
    return this.timeUntilBusinessPreviewExpiresMs === -1
  }

  get localizedCredits() {
    return (this.prepaidCredits / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get localizedAutoCharge() {
    return (this.autochargeAmount / 100).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
    })
  }

  get needsReview() {
    return this.reviewStatus === 'needs-review' || this.reviewStatus === 'needs-identity'
  }

  /**
   * Whether or not a user has limited functionality
   *
   * @see https://www.notion.so/openphone/Limited-KYC-and-Frozen-states-06455c6900c444dc83c50b04bcd5376e?pvs=4#89a91f1e94f84142939f44bb5b8998e8
   */
  get limitedState(): boolean {
    return this.reviewStatus === 'limited'
  }

  get frozenState(): boolean {
    return this.reviewStatus === 'frozen'
  }

  get isReviewRejected() {
    return this.reviewStatus === 'rejected'
  }

  get isReviewApproved() {
    return this.reviewStatus === 'approved' || this.reviewStatus === 'auto-approved'
  }

  get isKycProcessing() {
    return this.identityVerificationStatus === 'processing'
  }

  get hasKycExceeded() {
    return this.identityFailedCount >= 3
  }

  /**
   * The threshold under which auto-charge will be triggered, in cents.
   */
  get prepaidCreditThreshold() {
    return this.settings?.prepaidCreditThreshold ?? defaultPrepaidCreditThresholdCents
  }

  /**
   * Store type getters
   */
  get isStripe() {
    return this.store === 'stripe'
  }

  get isIos() {
    return this.store === 'ios'
  }

  get isAndroid() {
    return this.store === 'android'
  }

  deserialize = ({
    canceledAt,
    currentPeriodExpiresAt,
    currentPeriodStartedAt,
    originalStartedAt,
    businessPreviewExpiresAt,
    ...json
  }: EncodableSubscription) => {
    Object.assign(this, json)

    this.canceledAt = canceledAt ? parseDate(canceledAt) : null

    this.currentPeriodExpiresAt = currentPeriodExpiresAt
      ? parseDate(currentPeriodExpiresAt)
      : null

    this.currentPeriodStartedAt = currentPeriodStartedAt
      ? parseDate(currentPeriodStartedAt)
      : null

    this.originalStartedAt = originalStartedAt ? parseDate(originalStartedAt) : null

    this.businessPreviewExpiresAt = businessPreviewExpiresAt
      ? parseDate(businessPreviewExpiresAt)
      : null

    return this
  }

  serialize = (): CodableSubscription => {
    return {
      annual: this.annual,
      autochargeAmount: this.autochargeAmount,
      canceledAt: this.canceledAt,
      cancellationReason: this.cancellationReason,
      creditCardFingerprint: this.creditCardFingerprint,
      currentPeriodExpiresAt: this.currentPeriodExpiresAt,
      currentPeriodStartedAt: this.currentPeriodStartedAt,
      businessPreviewExpiresAt: this.businessPreviewExpiresAt,
      id: this.id,
      identityVerificationStatus: this.identityVerificationStatus,
      meta: toJS(this.meta),
      orgId: this.orgId,
      originalStartedAt: this.originalStartedAt,
      phoneNumbers: this.phoneNumbers,
      prepaidCredits: this.prepaidCredits,
      receipt: this.receipt,
      reviewStatus: this.reviewStatus,
      settings: toJS(this.settings),
      store: this.store,
      stripeCustomerId: this.stripeCustomerId,
      totalCredits: this.totalCredits,
      transactionId: this.transactionId,
      trial: this.trial,
      type: this.type,
      usedCredits: this.usedCredits,
      version: this.version,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      coupon: this.coupon,
      originatingUserId: this.originatingUserId,
      identityVerificationSecret: this.identityVerificationSecret,
      identityFailedCount: this.identityFailedCount,
    }
  }
}

export default SubscriptionModel
